import { ApiOptions } from "~/old-app/types/api";
import {
  useBranchCategoriesQuery,
  useBranchQuery,
  useBranchesListQuery,
  useBranchesQuery,
} from "./branch-api";
import { useState } from "react";
import { useConfigSlice } from "~/old-app/redux/config-slice";
import { ProductsQueryParams } from "../product";

export const useBranchCategories = (props?: ProductsQueryParams) => {
  const { selectedService, district_id } = useConfigSlice();
  const [page, setPage] = useState(1);
  const { data, isLoading, isFetching } = useBranchCategoriesQuery(
    {
      ...props,
      service_id: selectedService,
      page,
    },
    {
      skip: !selectedService || !district_id,
    }
  );

  // const { data, isLoading, isFetching } = useProductsQuery(
  //   {
  //     ...props,
  //     service_id: selectedService,
  //     page,
  //   },
  //   {
  //     skip: !selectedService || !district_id,
  //   }
  // );

  // branchCategories: data?.response?.data || [],
  // isLoading,
  // isFetching,

  const branchCategories = data?.response.data || [];
  const total = data?.response.meta?.total || 0;
  const hasMore = total > branchCategories.length;

  return {
    branchCategories,
    total: data?.response.meta?.total || 0,
    isLoading,
    isFetching,
    setPage,
    hasMore,
    page,
  };
};
export const useBranches = (
  props?: ProductsQueryParams,
  options?: ApiOptions
) => {
  const { selectedService, district_id } = useConfigSlice();
  const [page, setPage] = useState(1);

  const { data, isLoading, isFetching } = useBranchesQuery(
    {
      ...props,
      service_id: selectedService,
      page,
    },
    {
      skip: !selectedService || !district_id,
    }
  );

  const branches = data?.response.data || [];
  const total = data?.response.meta?.total || 0;
  const hasMore = total > branches.length;
  const meta = data?.response?.meta;
  const fetchNextPage = () => setPage((page) => page + 1);

  return {
    branches,
    total,
    isLoading,
    isFetching,
    setPage,
    hasMore,
    page,
    fetchNextPage,
    meta,
  };
};
export const useBranchesList = (
  props?: ProductsQueryParams,
  options?: ApiOptions
) => {
  const { selectedService, district_id } = useConfigSlice();
  const [page, setPage] = useState(1);

  const { data, isLoading, isFetching } = useBranchesListQuery(
    {
      ...props,
      service_id: selectedService,
      page,
    },
    {
      skip: !selectedService || !district_id || options?.skip,
    }
  );

  const branches = data?.response.data || [];
  const total = data?.response.meta?.total || 0;
  const hasMore = total > branches.length;
  const meta = data?.response?.meta;
  const fetchNextPage = () => setPage((page) => page + 1);

  return {
    branches,
    total,
    isLoading,
    isFetching,
    setPage,
    hasMore,
    page,
    fetchNextPage,
    meta,
  };
};
export const useBranch = (id?: string) => {
  const { data, isLoading, isFetching } = useBranchQuery(id, { skip: !id });
  return {
    branch: data?.response?.data,
    isLoading,
    isFetching,
  };
};
