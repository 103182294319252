import { dispatch } from "~/old-app/redux";
import { AuthActions } from "~/old-app/redux/auth-slice";
import { Response } from "../../types/api";
import { Api } from "../api-store";
import { Category } from "../category";
import { Branch, OpenBranchProps } from "./branch-types";
import { ProductsQueryParams } from "../product";

export const BranchApi = Api.injectEndpoints({
  endpoints: (builder) => ({
    branchCategories: builder.query<
      Response<Category[]>,
      ProductsQueryParams | undefined
    >({
      providesTags: ["branch-categories"],
      query: (params) => ({
        url: `/branch-categories`,
        method: "GET",
        params,
      }),
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      merge: (
        currentCache: Response<Category[]>,
        newItems: Response<Category[]>
      ) => {
        if (
          currentCache.response.data?.length + newItems.response.data?.length <=
          (newItems.response.meta?.total || 0)
        ) {
          currentCache.response.data.push(...newItems.response.data);
        }
        return currentCache as Response<Category[]>;
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
    }),
    branchesList: builder.query<
      Response<Branch[]>,
      ProductsQueryParams | undefined
    >({
      providesTags: ["branches"],
      query: (params) => ({
        url: `/branches`,
        method: "GET",
        params,
      }),
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      merge: (
        currentCache: Response<Branch[]>,
        newItems: Response<Branch[]>
      ) => {
        if (
          currentCache.response.data?.length + newItems.response.data?.length <=
          (newItems.response.meta?.total || 0)
        ) {
          currentCache.response.data.push(...newItems.response.data);
        }
        return currentCache as Response<Branch[]>;
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
    }),
    branches: builder.query<
      Response<Branch[]>,
      ProductsQueryParams | undefined
    >({
      providesTags: ["branches"],
      query: (params) => ({
        url: `/branches`,
        method: "GET",
        params,
      }),
    }),
    branch: builder.query<Response<Branch>, string | undefined>({
      query: (id) => ({
        url: `/branches/${id}`,
        method: "GET",
      }),
    }),

    branchRequest: builder.mutation<Response<Branch>, OpenBranchProps>({
      query: (body) => ({
        url: `/open-branch-requests`,
        method: "POST",
        body: body,
      }),
    }),
  }),
});

export const {
  useBranchCategoriesQuery,
  useBranchQuery,
  useBranchesQuery,
  useBranchesListQuery,
  useBranchRequestMutation,
} = BranchApi;
